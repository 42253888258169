import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

const reservationAccount = document.getElementById('rc-reservation-account');
if (typeof reservationAccount !== 'undefined' && reservationAccount !== null) {
    import('./components/ReservationAccount').then(({ ReservationAccount }) => {
        ReactDOM.render(
            <ReservationAccount {...JSON.parse(reservationAccount.dataset.viewmodel)} />,
            reservationAccount
        );
    });
}

const map = document.getElementById('rc-map');
if (typeof map !== 'undefined' && map !== null) {
    import('./components/MapProvider').then(({ MapProvider }) => {
        ReactDOM.render(<MapProvider {...JSON.parse(map.dataset.viewmodel)} />, map);
    });
}

const searchpage = document.getElementById('rc-searchpage');
if (typeof searchpage !== 'undefined' && searchpage !== null) {
    import('./components/Search/SearchPage').then(({ SearchPage }) => {
        ReactDOM.render(<SearchPage {...JSON.parse(searchpage.dataset.viewmodel)} />, searchpage);
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
